
import { Vue, Component } from 'vue-property-decorator';
import CountryFlag from 'vue-country-flag'
import { appName } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer } from '@/store/main/mutations';
import {readLanguage} from '@/store/admin/getters';
import {commitSwitchLanguage} from '@/store/admin/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';
import { commitDeleteEveryThing } from '@/store/admin/mutations';


const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component({
  components: {
    CountryFlag,
  }
})
export default class Main extends Vue {
  public appName = appName;

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    if(!this.hasAdminAccess) {
      return false;
    }
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  public switchShowDrawer() {
    if(this.hasAdminAccess){commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );}
  }

  public routerBack() {
    this.$router.back();
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  get language() {
    return readLanguage(this.$store);
  }
  
  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if(this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if(!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
    commitDeleteEveryThing(this.$store);
  }

  public goToDash() {
    this.$router.push("/main/dashboard").catch(failure => {});
  }
}
